













































import { Component } from 'vue-property-decorator';
import TableComponent from '@common-src/mixins/table-component';
import { TopoPageEntityModel, TopoPageQueryModel } from './model/topo-page-entity-model';
import TopoService from './service/topo-service';

@Component
export default class TopoListComponent extends TableComponent<TopoPageEntityModel, TopoPageQueryModel> {
    created() {
        this.initTable({
            service: TopoService,
            queryModel: new TopoPageQueryModel(),
            tableColumns: TopoPageEntityModel.getTableColumns()
        });
        this.getList();
    }

    addClick() {
        this.$router.push('/topo/editor');
    }

    editClick(model: TopoPageEntityModel) {
        this.$router.push(`/topo/editor/${model.id}`);
    }
    previewClick(model: TopoPageEntityModel) {
        window.open(`/topo/preview/${model.id}`, '_blank');
    }
}

